<template>
  <u-form ref="form" class="lets-get-acquainted">
    <div class="lets-get-acquainted__title u-typography-helvetica u-text-display--s">
      {{ $t('getAcquaintedPage.title') }}
    </div>
    <div class="lets-get-acquainted__text u-typography-helvetica u-text--xs">
      {{ $t('getAcquaintedPage.introText') }}
    </div>
    <u-input
      class="lets-get-acquainted__text-input"
      :value="value.firstName"
      :label="$t('getAcquaintedPage.label.firstName')"
      :validators="[validators.REQUIRED]"
      @input="onFieldChange('firstName', $event)"
    />
    <u-input
      class="lets-get-acquainted__email-input"
      :value="value.email"
      type="email"
      :label="$t('getAcquaintedPage.label.email')"
      :validators="[validators.REQUIRED, validators.EMAIL]"
      @input="onFieldChange('email', $event)"
    />
    <u-dropdown
      v-if="hasShippingField"
      :value="value.shippingTo"
      class="lets-get-acquainted__dropdown"
      caption="These countries are included in our shipping area"
      :options="['France', 'USA', 'Russia']"
    />

    <div
      v-if="error"
      class="lets-get-acquainted__error-message u-typography-helvetica u-text u-text--xs"
    >
      <u-icon name="alert-triangle" />
      {{ error }}
    </div>
  </u-form>
</template>

<script>
import { UInput, UForm, UDropdown, UIcon } from 'universkin-shared';

import { validatable } from '@/mixins/validatable';

export default {
  name: 'LetsGetAcquaintedForm',
  components: { UInput, UForm, UDropdown, UIcon },
  mixins: [validatable],
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasShippingField() {
      // TODO: implement after we have automatic flow
      return false;
    }
  },
  methods: {
    onFieldChange(fieldName, fieldValue) {
      this.$emit('input', { ...this.value, [fieldName]: fieldValue });
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors.scss';
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.lets-get-acquainted {
  position: relative;
  width: 270px;
  max-width: 100%;
  height: 450px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    color: $color-graphite;
    text-align: center;
  }

  &__text {
    width: 100%;

    margin: 0 auto 16px auto;
    color: $color-grey600;
    text-align: center;
  }

  &__text-input {
    margin-bottom: 20px;
  }

  &__email-input {
    margin-bottom: 20px;
  }

  &__dropdown {
    width: 100%;

    text-align: left;
  }

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
  }
}

@media (min-width: $desktop-start) {
  .lets-get-acquainted {
    width: 350px;

    &__text {
      margin-bottom: 32px;
    }

    &__email-input {
      margin-bottom: 25px;
    }
  }
}
</style>
