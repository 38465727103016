<template>
  <lets-get-acquainted-page-template
    v-model="accessDetails"
    :locale="locale"
    :language-options="languageOptions"
    :error="errorMessage"
    :loading="isLoading"
    @change-language="onChangeLanguage"
    @back="onBackClick"
    @continue="onContinueClick"
  />
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import LetsGetAcquaintedPageTemplate from '@/modules/questionnaire/pages/lets-get-acquainted/LetsGetAcquaintedPageTemplate';

import { getDecodedId } from '@/utils';
import { hasResponseErrors } from '@/utils/storeActionsUtils';
import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';

import { EMAIL_ERRORS, VALIDATION_ERRORS } from '@/constants';

import { types as questTypes } from '@/modules/questionnaire/store/types';

export default {
  name: 'PageLetsGetAcquainted',
  components: { LetsGetAcquaintedPageTemplate },
  mixins: [changeLanguageMixin],
  props: {
    doctorHash: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      accessDetails: {
        firstName: '',
        email: ''
      },
      errorMessage: '',
      isLoading: false
    };
  },
  methods: {
    ...mapMutations({
      setAccessDetails: questTypes.mutations.SET_ACCESS_DETAILS
    }),
    ...mapActions({
      requestQuestionnaireAccess: questTypes.actions.REQUEST_QUESTIONNAIRE_ACCESS
    }),
    onBackClick() {
      this.$router.go(-1);
    },
    resetErrorState() {
      this.errorMessage = '';
    },
    async onContinueClick() {
      this.resetErrorState();

      const [currentDoctorId] = getDecodedId(this.doctorHash || '');

      this.isLoading = true;
      const response = await this.requestQuestionnaireAccess({
        currentDoctorId,
        ...this.accessDetails
      });
      this.isLoading = false;

      if (!hasResponseErrors(response)) {
        await this.$router.replace({ name: 'DataIsSafeWithUs' });

        return;
      }

      const { errors } = response;

      if (errors.includes(EMAIL_ERRORS.EMAIL_USED_BY_DOCTOR)) {
        this.errorMessage = this.$t('dashboard.warning.doctorExist');
        return;
      }

      if (errors.includes(VALIDATION_ERRORS.EMAIL_VERIFICATION_REQUIRED)) {
        this.setAccessDetails({ currentDoctorId, ...this.accessDetails });
        await this.$router.replace({ name: 'QuestionnaireEmailVerification' });
      }
    }
  }
};
</script>
