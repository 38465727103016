<template>
  <questionnaire-initial-page-template
    :language="locale"
    :language-options="languageOptions"
    :loading="loading"
    vertically-center-content
    :is-continue-disabled="isContinueDisabled"
    @change-language="onChangeLanguage"
    @back="$emit('back')"
    @continue="onContinue"
  >
    <lets-get-acquainted-form
      ref="getAcquaintedForm"
      :value="value"
      :error="error"
      @input="$emit('input', $event)"
    />
  </questionnaire-initial-page-template>
</template>

<script>
import QuestionnaireInitialPageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnaireInitialPageTemplate';
import LetsGetAcquaintedForm from '@/modules/questionnaire/pages/lets-get-acquainted/LetsGetAcquaintedForm';

export default {
  name: 'LetsGetAcquaintedPageTemplate',
  components: { QuestionnaireInitialPageTemplate, LetsGetAcquaintedForm },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: ''
    },
    languageOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isContinueDisabled() {
      const { firstName, email } = this.value;

      return !firstName || !email;
    }
  },
  methods: {
    onContinue() {
      const isValid = this.$refs.getAcquaintedForm.validate();

      if (isValid) {
        this.$emit('continue');
      }
    },
    onChangeLanguage(code) {
      this.$emit('change-language', code);
    }
  }
};
</script>
